import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '@layouts';
import Meta from '@shared/Meta/Meta.query';
import Hero from '@components/Hero/Hero.query';
import TextHero from '@components/TextHero/TextHero.query';
import QuoteModule from '@components/QuoteModule/QuoteModule.query';
import LetterFromDenis from '@components/LetterFromDenis/LetterFromDenis.query';
import VideoModule from '@components/VideoModule/VideoModule.query';
import OurHistory from '@components/OurHistory/OurHistory.query';
import DonationModule from '@shared/DonationModule/DonationModule.query';
import SocialModule from '@shared/SocialModule/SocialModule.query';
import BoardMemberCarousel from '@components/BoardMemberCarousel/BoardMemberCarousel.query';

const IndexPage = ({ data }) => {
  const letterFromDenis = data.allContentfulHome.edges.map(
    edge => edge.node.letterFromDenis
  )[0];

  const hero = data.allContentfulHome.edges.map(edge => edge.node.hero)[0];

  const textHero = data.allContentfulHome.edges.map(
    edge => edge.node.textHero
  )[0];

  const videoModule = data.allContentfulHome.edges.map(
    edge => edge.node.videoModule
  )[0];

  const ourHistoryModule = data.allContentfulHome.edges.map(
    edge => edge.node.ourHistoryModule
  )[0];

  const quoteModule = data.allContentfulHome.edges.map(
    edge => edge.node.quoteModule
  )[0];

  const boardMembersModule = data.allContentfulHome.edges.map(
    edge => edge.node.boardMembersModule
  )[0];

  const globalSiteData = data.allContentfulGlobalSiteData.edges.map(
    edge => edge.node
  )[0];

  const {
    facebookLink,
    twitterLink,
    instagramLink,
    youTubeLink,
    socialModuleEyebrow,
    socialModuleTitle,
    socialModuleSubtitle,
    donationModuleEyebrow,
    donationModuleTitle,
    donationModuleSubtitle,
    mailingAddressLine1,
    mailingAddressLine2,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    cookieBannerCopy,
    cookieBannerCtaCopy,
    phoneNumber,
    contactEmails,
  } = globalSiteData;

  const footerData = {
    mailingAddressLine1,
    mailingAddressLine2,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    phoneNumber,
    facebookLink,
    twitterLink,
    instagramLink,
    youTubeLink,
    contactEmails,
  };

  const cookieBanner = {
    cookieBannerCopy,
    cookieBannerCtaCopy,
  };

  return (
    <DefaultLayout
      footerData={footerData}
      cookieBanner={cookieBanner}
      pageName="Home"
    >
      <Meta {...globalSiteData} />

      <Hero {...hero} />

      <TextHero {...textHero} />

      <VideoModule {...videoModule} />

      <QuoteModule {...quoteModule} />

      <LetterFromDenis {...letterFromDenis} />

      <OurHistory {...ourHistoryModule} />

      <BoardMemberCarousel {...boardMembersModule} />

      <SocialModule
        eyebrow={socialModuleEyebrow}
        title={socialModuleTitle}
        subtitle={socialModuleSubtitle}
        links={[
          {
            label: 'Instagram',
            url: instagramLink,
          },
          {
            label: 'Facebook',
            url: facebookLink,
          },
          {
            label: 'Twitter',
            url: twitterLink,
          },
          {
            label: 'YouTube',
            url: youTubeLink,
          },
        ]}
      />

      <DonationModule
        donationModuleEyebrow={donationModuleEyebrow}
        donationModuleTitle={donationModuleTitle}
        donationModuleSubtitle={donationModuleSubtitle}
      />
    </DefaultLayout>
  );
};

export default IndexPage;

// Graphql Query
export const pageQuery = graphql`
  {
    allContentfulGlobalSiteData {
      edges {
        node {
          ...Meta
          ...SocialModule
          ...DonationModule
        }
      }
    }

    allContentfulHome {
      edges {
        node {
          letterFromDenis {
            ...LetterFromDenis
          }
          hero {
            ...Hero
          }

          textHero {
            ...TextHero
          }

          videoModule {
            ...VideoModule
          }

          ourHistoryModule {
            ...OurHistoryModule
          }

          quoteModule {
            ...QuoteModule
          }

          boardMembersModule {
            ...BoardMemberCarousel
          }
        }
      }
    }
  }
`;
