import React, { useState, useEffect } from 'react';
import useWindowSize from '@hooks/useWindowSize';
import DesktopVideoModule from './DesktopVideoModule';
import MobileVideoModule from './MobileVideoModule';

const VideoModule = ({ videosList }) => {
  const [mounted, setMounted] = useState(false);
  const { windowWidth } = useWindowSize();

  // In production, the window size isn't available until the component is already mounted.
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  if (windowWidth <= 768) {
    return (
      <MobileVideoModule videosList={videosList} windowWidth={windowWidth} />
    );
  }

  return <DesktopVideoModule videosList={videosList} />;
};

export default VideoModule;
