import React, { useState } from 'react';
import { PlusIcon, CloseX } from '@shared/Icons/Icons';
import Img from 'gatsby-image/withIEPolyfill';
import { css, isTouchDevice } from '@utils';
import Styles from './BoardMember.module.scss';

const BoardMember = ({
  index,
  title,
  name,
  bio,
  image,
  bioActive,
  onBioClick,

  boardMemberItemSize,
  inView,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div
      className={css(
        Styles.member,
        imageLoaded && Styles.imageLoaded,
        inView && Styles.inView
      )}
      style={{
        width: `${boardMemberItemSize}%`,
        transitionDelay: `${index * 100 + 700}ms`,
      }}
      onClick={e => {
        if (!bioActive && isTouchDevice) {
          onBioClick(e, index);
        }
      }}
    >
      <div className={Styles.memberImage}>
        {image && image.fluid && (
          <Img
            fluid={image.fluid}
            onLoad={() => {
              setImageLoaded(true);
            }}
          />
        )}
      </div>
      <div className={Styles.copyTintBackground} />

      <div className={Styles.memberCopyContainer}>
        <button
          className={Styles.plusIconButton}
          aria-label="Show board member bio"
          type="button"
          onClick={e => {
            onBioClick(e, index);
          }}
        >
          <PlusIcon iconStyles={Styles.plusIcon} />
        </button>
        <div className={Styles.memberTitle}>{title}</div>
        <div className={Styles.memberName}>{name}</div>
      </div>
      <div
        className={css(Styles.memberBioContainer, bioActive && Styles.active)}
      >
        <div className={Styles.memberBioBackground} />

        <button
          type="button"
          onClick={e => {
            onBioClick(e, -1);
          }}
        >
          <CloseX iconStyles={Styles.closeButton} />
        </button>
        <div
          className={Styles.memberBio}
          dangerouslySetInnerHTML={{ __html: bio }}
        />
      </div>
    </div>
  );
};

export default BoardMember;
