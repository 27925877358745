import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import Meta from './Meta';

const MetaQuery = props => {
  const pageTitle = idx(props, _ => _.pageTitle);
  const pageDescription = idx(props, _ => _.pageDescription);
  const shareTitle = idx(props, _ => _.shareTitle);
  const shareDescription = idx(props, _ => _.shareDescription);
  const shareImage = idx(props, _ => _.shareImage.fixed);
  const twitterHandle = idx(props, _ => _.twitterHandle);

  return (
    <Meta
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      shareTitle={shareTitle}
      shareDescription={shareDescription}
      shareImage={shareImage}
      twitterHandle={twitterHandle}
    />
  );
};

export default MetaQuery;

export const query = graphql`
  fragment Meta on ContentfulGlobalSiteData {
    pageTitle
    pageDescription

    shareTitle
    shareDescription
    shareImage {
      fixed(width: 1200, height: 630, quality: 70) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    facebookLink
    instagramLink
    twitterLink
    twitterHandle
    youTubeLink
    socialModuleEyebrow
    socialModuleTitle
    socialModuleSubtitle
    mailingAddressLine1
    mailingAddressLine2
    mailingAddressCity
    mailingAddressState
    mailingAddressZipCode
    phoneNumber
    contactEmails
    cookieBannerCopy {
      childMarkdownRemark {
        html
      }
    }

    cookieBannerCtaCopy
  }
`;
