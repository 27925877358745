import React, { useState, useRef } from 'react';
import idx from 'idx';
import { css } from '@utils';
import { InView } from 'react-intersection-observer';
import Video from './Video/Video';
import Styles from './DesktopVideoModule.module.scss';

const DesktopVideoModule = ({ videosList }) => {
  const [activePreviewIndex, setActivePreviewIndex] = useState(0);
  const [activeYoutubeIndex, setActiveYoutubeIndex] = useState(-1);

  const videosContainerRef = useRef(null);

  const onClick = videoIndex => {
    setActivePreviewIndex(videoIndex);
  };

  const onVideoClick = () => {
    if (activeYoutubeIndex === -1) {
      setActiveYoutubeIndex(activePreviewIndex);
    }
  };

  const onYoutubeClose = () => {
    setActiveYoutubeIndex(-1);
  };

  const playNextVideo = () => {
    const nextVideoIndex =
      activePreviewIndex + 1 > videosList.length - 1
        ? 0
        : activePreviewIndex + 1;

    setActivePreviewIndex(nextVideoIndex);
  };

  return (
    <InView triggerOnce threshold={0}>
      {({ inView, ref }) => (
        <div
          ref={ref}
          className={css(Styles.videoModule, inView && Styles.inView)}
        >
          <div className={Styles.videoTabsContainer}>
            <ul className={Styles.videoTabs}>
              {videosList.map((video, index) => {
                const title = idx(video, _ => _.title);
                const metaInfo = idx(video, _ => _.metaInfo);

                return (
                  <li
                    key={index}
                    className={css(
                      Styles.videoTab,
                      index === activePreviewIndex && Styles.active
                    )}
                    style={{ animationDelay: `${index * 100}ms` }}
                  >
                    <button
                      type="button"
                      className={Styles.tabCopyContainer}
                      onClick={() => {
                        onClick(index);
                      }}
                    >
                      <div className={Styles.tabTitle}>{title}</div>
                      <div className={Styles.tabMetaInfo}>{metaInfo}</div>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            className={css(
              Styles.videosContainer,
              activeYoutubeIndex > -1 && Styles.disabled
            )}
            ref={videosContainerRef}
          >
            <div className={Styles.videoActionCursor} onClick={onVideoClick}>
              Play
            </div>
            <div className={Styles.spaceHolder} />
            {videosList.map((video, index) => {
              const videoPreviewFileSrc = idx(
                video,
                _ => _.videoPreview.file.url
              );
              const youtubeVideoId = idx(video, _ => _.youtubeVideoId);
              const metaInfo = idx(video, _ => _.metaInfo);

              return (
                <Video
                  key={index}
                  inView={inView}
                  active={index === activePreviewIndex}
                  src={videoPreviewFileSrc}
                  youtubeVideoId={youtubeVideoId}
                  metaInfo={metaInfo}
                  youtubeActive={index === activeYoutubeIndex}
                  onYoutubeClose={onYoutubeClose}
                  playNextVideo={playNextVideo}
                />
              );
            })}
          </div>
        </div>
      )}
    </InView>
  );
};

export default DesktopVideoModule;
