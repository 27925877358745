import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import VideoModule from './VideoModule';

const VideoModuleQuery = props => {
  const videosList = idx(props, _ => _.videosList);
  return <VideoModule videosList={videosList} />;
};

export default VideoModuleQuery;

export const query = graphql`
  fragment VideoModule on ContentfulVideoModule {
    videosList {
      id
      title
      metaInfo
      mobileVideoThumbnail {
        description
        fluid(maxWidth: 614, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      videoPreview {
        file {
          url
        }
      }
      youtubeVideoId
    }
  }
`;
