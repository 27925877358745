import React, { useState, useRef, useEffect } from 'react';
import idx from 'idx';
import { InView } from 'react-intersection-observer';

import { PlayIcon } from '@shared/Icons/Icons';
import YoutubeIframe from '@shared/YoutubeIframe/YoutubeIframe';

import Img from 'gatsby-image/withIEPolyfill';

import { css, getSlideIndexFromProgress, trackEvent } from '@utils';
import * as gsap from 'gsap';
import Styles from './MobileVideoModule.module.scss';
import { isTouchDevice } from '../../utils';

const MobileVideoModule = ({ videosList, windowWidth }) => {
  const [activeYoutubeIndex, setActiveYoutubeIndex] = useState(-1);
  const carouselRef = useRef(null);
  const carouselProgress = useRef(0);
  const currentProgress = useRef(0);
  const pressPoint = useRef(null);
  const carouselTweenId = useRef(null);
  const positionTweenId = useRef(null);
  const slideIndex = useRef(0);

  const pressingDown = useRef(false);

  const slideSize = useRef(getSlideSize(windowWidth)); // % of width
  const guestureDirection = useRef('left');
  const thumbnailItemRefs = useRef(new Array(videosList.length).fill(null));

  const moveCarousel = () => {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.style.transform = `translate3d(${currentProgress.current}px, 0, 0)`;
    }
  };

  const tweenPosition = (from, to) => {
    if (positionTweenId.current) {
      positionTweenId.current.kill();
    }

    if (carouselTweenId.current) {
      carouselTweenId.current.kill();
    }

    const carouselPosition = { x: from };
    positionTweenId.current = gsap.TweenLite.to(carouselPosition, 0.2, {
      x: to,
      ease: gsap.Expo.easeOut,
      onUpdate: () => {
        currentProgress.current = carouselPosition.x;
        moveCarousel();
      },
    });
  };

  const updateActiveSlide = () => {
    thumbnailItemRefs.current.forEach((ref, index) => {
      if (index === slideIndex.current) {
        ref.className = css(Styles.videoCardSlide, Styles.slideActive);
      } else {
        ref.className = Styles.videoCardSlide;
      }
    });
  };

  const tweenToSlide = index => {
    const progressTween = { val: currentProgress.current };
    slideIndex.current = index;
    updateActiveSlide();

    if (carouselTweenId.current) {
      carouselTweenId.current.kill();
    }

    if (positionTweenId.current) {
      positionTweenId.current.kill();
    }

    carouselTweenId.current = gsap.TweenLite.to(progressTween, 0.7, {
      val: -1 * index * (window.innerWidth * (slideSize.current / 100)),
      onUpdate: () => {
        currentProgress.current = progressTween.val;
        carouselProgress.current = progressTween.val;

        moveCarousel();
      },
    });
  };

  const onPressStart = e => {
    if (e.touches) {
      pressPoint.current = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    } else {
      pressPoint.current = { x: e.clientX, y: e.clientY };
    }
    pressingDown.current = true;
  };

  const onPressMove = e => {
    if (!pressPoint.current) {
      return;
    }

    if (!pressingDown.current) {
      return;
    }

    if (carouselTweenId.current) {
      carouselTweenId.current.kill();
    }

    const clientX = e.touches ? e.touches[0].clientX : e.clientX;

    guestureDirection.current =
      clientX < pressPoint.current.x
        ? 'left'
        : clientX > pressPoint.current.x
        ? 'right'
        : null;

    const timelineDelta = clientX - pressPoint.current.x;
    const prevProgress = currentProgress.current;

    tweenPosition(prevProgress, carouselProgress.current + timelineDelta);
  };

  const onPressEnd = () => {
    pressingDown.current = false;
    const progressX =
      (-1 * currentProgress.current) /
      ((slideSize.current / 100) * window.innerWidth);
    const progressModulus = progressX % 1;

    const progressIndex =
      guestureDirection.current === 'left'
        ? Math.floor(progressX)
        : guestureDirection.current === 'right'
        ? Math.ceil(progressX)
        : Math.round(progressX);

    const newIndex = getSlideIndexFromProgress(
      guestureDirection.current,
      progressModulus,
      progressIndex
    );

    const constrainedSlideIndex = getConstrainedIndex(
      newIndex,
      videosList.length,
      100 / slideSize.current // items per width =>  2.5 etc
    );

    tweenToSlide(constrainedSlideIndex);

    carouselProgress.current = currentProgress.current;
  };

  const onSlideClick = index => {
    const currentVideo =
      videosList.length > 0 && videosList[index]
        ? videosList[index]
        : `Video ${index + 1}`;
    const { title: videoTitle } = currentVideo;

    trackEvent({
      category: 'Home',
      action: null,
      label: videoTitle,
    });

    setActiveYoutubeIndex(index);
  };

  const onResize = () => {
    slideSize.current = getSlideSize(window.innerWidth);
    tweenToSlide(slideIndex.current);
  };

  const bindListeners = () => {
    if (!isTouchDevice) {
      window.addEventListener('resize', onResize);
    }
  };

  useEffect(() => {
    bindListeners();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <InView triggerOnce threshold={0}>
      {({ inView, ref }) => (
        <div
          ref={ref}
          className={css(Styles.mobileVideoModule, inView && Styles.inView)}
        >
          <div
            role="presentation"
            className={Styles.videosCarousel}
            ref={carouselRef}
            style={{
              width: `${videosList.length * 100}%`,
            }}
            onTouchStart={onPressStart}
            onTouchMove={onPressMove}
            onTouchEnd={onPressEnd}
            onMouseDown={onPressStart}
            onMouseMove={onPressMove}
            onMouseUp={onPressEnd}
            onMouseLeave={onPressEnd}
          >
            {videosList.map((video, index) => {
              const title = idx(video, _ => _.title);
              const metaInfo = idx(video, _ => _.metaInfo);
              const mobileVideoThumbnail = idx(
                video,
                _ => _.mobileVideoThumbnail
              );

              return (
                <div
                  key={index}
                  className={css(
                    Styles.videoCardSlide,
                    index === slideIndex.current && Styles.slideActive
                  )}
                  style={{
                    width: `${slideSize.current / videosList.length}%`,
                    transitionDelay: `${index * 100}ms`,
                  }}
                  ref={r => {
                    thumbnailItemRefs.current[index] = r;
                  }}
                >
                  {mobileVideoThumbnail && mobileVideoThumbnail.fluid && (
                    <Img
                      className={Styles.mobileThumbnail}
                      fluid={mobileVideoThumbnail.fluid}
                    />
                  )}
                  <div className={css(Styles.copyContainer)}>
                    <div className={Styles.copyContainerBackground} />
                    <button
                      type="button"
                      className={Styles.playIconContainer}
                      onClick={() => {
                        onSlideClick(index);
                      }}
                    >
                      <PlayIcon iconStyles={Styles.playIcon} />
                    </button>
                    <div className={Styles.title}>{title}</div>
                    <div className={Styles.metaInfo}>{metaInfo}</div>
                  </div>
                </div>
              );
            })}
          </div>
          {videosList.map((video, index) => {
            const youtubeVideoId = idx(video, _ => _.youtubeVideoId);

            return (
              <YoutubeIframe
                key={index}
                youtubeVideoId={youtubeVideoId}
                active={index === activeYoutubeIndex}
                onClose={() => {
                  setActiveYoutubeIndex(-1);
                }}
              />
            );
          })}
        </div>
      )}
    </InView>
  );
};

const getConstrainedIndex = (newIndex, total, itemsPerWidth) => {
  return Math.min(
    Math.max(0, newIndex),
    total > Math.floor(itemsPerWidth)
      ? total - Math.floor(itemsPerWidth)
      : total - 1
  );
};

const getSlideSize = width => {
  if (width <= 600) {
    return 85;
  }

  return 40;
};

export default MobileVideoModule;
