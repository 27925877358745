import React, { useState, useRef, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import {
  css,
  mapRange,
  getSlideIndexFromProgress,
  isTouchDevice,
} from '@utils';
import { ShortArrow } from '@shared/Icons/Icons';
import * as gsap from 'gsap';
import Styles from './OurHistory.module.scss';

const OurHistory = ({
  eyebrow,
  title,
  subtitle,
  timelineDetails: historyItems,
}) => {
  const carouselRef = useRef(null);
  const timelineContainerRef = useRef(null);
  const leftDesktopArrowRef = useRef(null);
  const rightDesktopArrowRef = useRef(null);
  const carouselWidth = useRef(0);

  const historyItemRefs = useRef(new Array(historyItems.length).fill(null));
  const [mounted, setMounted] = useState(false);
  const guestureDirection = useRef(null);

  const activeDotRef = useRef(null);
  const pressPoint = useRef(null);
  const carouselProgress = useRef(0);
  const currentProgress = useRef(0);
  const carouselTweenId = useRef(null);
  const slideIndex = useRef(0);
  const positionTweenId = useRef(null);
  const pressingDown = useRef(false);

  const [windowWidth, setWindowWidth] = useState(0);
  const historyItemSize = useRef(getItemSize(windowWidth));
  const firstRender = useRef(true);

  const moveTimeline = () => {
    carouselRef.current.style.transition = 'none';
    carouselRef.current.style.transform = `translate3d(${currentProgress.current}px, 0, 0)`;
    timelineContainerRef.current.style.transform = `translate3d(${currentProgress.current}px, 0, 0)`;
    if (window.innerWidth <= 600) {
      updateSlideRotation();
    }
    // no overscroll to the left for the active dot.
    if (currentProgress.current > 0) {
      activeDotRef.current.style.transform = `translate3d(0, 0, 0)`;
    } else {
      activeDotRef.current.style.transform = `translate3d(${-1 *
        currentProgress.current}px, 0, 0)`;
    }
  };

  const tweenPosition = (from, to) => {
    if (positionTweenId.current) {
      positionTweenId.current.kill();
    }

    if (carouselTweenId.current) {
      carouselTweenId.current.kill();
    }

    const carouselPosition = { x: from };
    positionTweenId.current = gsap.TweenLite.to(carouselPosition, 0.2, {
      x: to,
      ease: gsap.Expo.easeOut,
      onUpdate: () => {
        moveTimeline(carouselPosition.x);
      },
    });
  };

  const updateSlideRotation = () => {
    const progressX =
      (-1 * currentProgress.current) /
      ((historyItemSize.current / 100) * windowWidth);
    historyItemRefs.current.forEach((ref, index) => {
      if (!ref) {
        return;
      }

      const slideRef = ref;

      const progressDifference = progressX - index;
      // scale is always between [.5, 1]
      const opacity =
        1 - Math.abs(Math.min(Math.max(0, progressDifference), 1));

      const scale = mapRange(
        1 - Math.abs(Math.min(Math.max(0, progressDifference), 1)),
        0,
        1,
        0.7,
        1
      );

      slideRef.style.transition = 'none';
      if (!firstRender) {
        slideRef.style.opacity = opacity;
      }

      slideRef.style.transform = `scale(${scale})`;

      firstRender.current = false;
    });
  };

  const updateDesktopArrows = () => {
    if (slideIndex.current === 0) {
      leftDesktopArrowRef.current.className = css(
        Styles.leftArrow,
        Styles.disabled
      );
    } else {
      leftDesktopArrowRef.current.className = Styles.leftArrow;
    }

    if (slideIndex.current === historyItems.length - 1) {
      rightDesktopArrowRef.current.className = css(
        Styles.rightArrow,
        Styles.disabled
      );
    } else {
      rightDesktopArrowRef.current.className = Styles.rightArrow;
    }
  };

  const tweenToSlide = (
    index,
    animationEase = gsap.TweenLite.defaultEase,
    animationDuration = 0.35
  ) => {
    const progressTween = { val: currentProgress.current };

    if (carouselTweenId.current) {
      carouselTweenId.current.kill();
    }

    if (positionTweenId.current) {
      positionTweenId.current.kill();
    }

    updateDesktopArrows(index);

    carouselTweenId.current = gsap.TweenLite.to(
      progressTween,
      animationDuration,
      {
        val:
          -1 *
          index *
          ((historyItemSize.current / 100) *
            (carouselWidth.current / historyItems.length)),
        ease: animationEase,
        onUpdate: () => {
          currentProgress.current = progressTween.val;
          carouselProgress.current = progressTween.val;
          moveTimeline();
        },
      }
    );
  };

  const onPressStart = e => {
    if (e.touches) {
      pressPoint.current = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    } else {
      pressPoint.current = { x: e.clientX, y: e.clientY };
    }
    pressingDown.current = true;
  };

  const onPressMove = e => {
    if (!pressPoint.current) {
      return;
    }

    if (!pressingDown.current) {
      return;
    }

    if (carouselTweenId.current) {
      carouselTweenId.current.kill();
    }

    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    guestureDirection.current =
      clientX < pressPoint.current.x
        ? 'left'
        : clientX > pressPoint.current.x
        ? 'right'
        : null;

    const timelineDelta = clientX - pressPoint.current.x;
    const prevProgress = currentProgress.current;
    currentProgress.current = carouselProgress.current + timelineDelta;
    tweenPosition(prevProgress, currentProgress.current);
  };

  const onPressEnd = () => {
    pressingDown.current = false;
    const progressX =
      (-1 * currentProgress.current) /
      ((historyItemSize.current / 100) *
        (carouselWidth.current / historyItems.length));

    const progressIndex =
      guestureDirection.current === 'left'
        ? Math.floor(progressX)
        : guestureDirection.current === 'right'
        ? Math.ceil(progressX)
        : Math.round(progressX);

    const progressModulus = progressX % 1;

    const newIndex = getSlideIndexFromProgress(
      guestureDirection.current,
      progressModulus,
      progressIndex
    );

    const constrainedSlideIndex = Math.min(
      Math.max(0, newIndex),
      historyItems.length - 1
    );

    slideIndex.current = constrainedSlideIndex;
    carouselProgress.current = currentProgress.current;
    tweenToSlide(constrainedSlideIndex);
  };

  const onDesktopArrowClick = direction => {
    const slideChangeAmount = 1;
    const newSlideIndex =
      direction === 'left'
        ? slideIndex.current - slideChangeAmount
        : slideIndex.current + slideChangeAmount;

    const constrainedSlideIndex =
      newSlideIndex < 0
        ? 0
        : newSlideIndex > historyItems.length - 1
        ? historyItems.length - 1
        : Math.round(newSlideIndex);

    slideIndex.current = constrainedSlideIndex;
    tweenToSlide(constrainedSlideIndex, gsap.Power2.easeInOut, 1.2);
  };

  const onDotClick = newSlideIndex => {
    slideIndex.current = newSlideIndex;
    tweenToSlide(newSlideIndex, gsap.Power2.easeInOut, 1.2);
  };

  const bindResizeListener = () => {
    window.addEventListener('resize', onResize);
  };

  let resizeTimeout = null;
  const onResize = () => {
    if (resizeTimeout) {
      clearTimeout(resizeTimeout);
    }
    resizeTimeout = setTimeout(() => {
      setWindowWidth(window.innerWidth);
    }, 0);
  };

  useEffect(() => {
    if (!isTouchDevice && carouselRef.current) {
      carouselWidth.current = carouselRef.current.getBoundingClientRect().width;
      historyItemSize.current = getItemSize(windowWidth);

      if (!firstRender.current) {
        tweenToSlide(slideIndex.current);
      }
      firstRender.current = false;
    }
  }, [windowWidth]);

  useEffect(() => {
    setMounted(true);
    bindResizeListener();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (mounted) {
      onResize();
    }
  }, [mounted]);

  useEffect(() => {
    if (carouselRef.current) {
      carouselWidth.current = carouselRef.current.getBoundingClientRect().width;
    }
  });

  if (!mounted) {
    return null;
  }

  return (
    <InView triggerOnce threshold={0.5}>
      {({ inView, ref }) => (
        <div
          ref={ref}
          className={css(Styles.ourHistory, inView && Styles.inView)}
        >
          <div className={Styles.copyContainer}>
            <div className={Styles.eyebrow}>{eyebrow}</div>
            <div className={Styles.title}>{title}</div>
            <div className={Styles.subtitle}>{subtitle}</div>
          </div>
          <div className={Styles.desktopArrows}>
            <button
              type="button"
              aria-label="left arrow"
              className={css(Styles.leftArrow, Styles.disabled)}
              ref={leftDesktopArrowRef}
              onClick={() => {
                onDesktopArrowClick('left');
              }}
            >
              <ShortArrow iconStyles={Styles.svgArrowIcon} />
            </button>
            <button
              type="button"
              aria-label="right arrow"
              className={Styles.rightArrow}
              ref={rightDesktopArrowRef}
              onClick={() => {
                onDesktopArrowClick('right');
              }}
            >
              <ShortArrow iconStyles={Styles.svgArrowIcon} />
            </button>
          </div>
          <div
            className={Styles.timelineContainer}
            ref={timelineContainerRef}
            style={{
              width: `${historyItems.length * 100}%`,
            }}
          >
            <div className={Styles.activeDot} ref={activeDotRef} />
            <div className={Styles.line}>
              {historyItems.map((line, index) => {
                if (index === historyItems.length - 1) {
                  return (
                    <div
                      key={index}
                      className={Styles.dottedLine}
                      style={{
                        width: `${2 * windowWidth}px`,
                        transform: `translate3d(${index *
                          (historyItemSize.current / 100) *
                          windowWidth}px, -1px, 0)`,
                      }}
                    />
                  );
                }
                return (
                  <div
                    key={index}
                    className={Styles.solidLine}
                    style={{
                      width: `${(historyItemSize.current / 100) *
                        windowWidth}px`,
                      transform: `translate3d(${index *
                        (historyItemSize.current / 100) *
                        windowWidth}px, 0, 0)`,
                    }}
                  />
                );
              })}
            </div>

            {historyItems.map((_, index) => {
              return (
                <div
                  className={Styles.dot}
                  key={index}
                  onClick={() => {
                    onDotClick(index);
                  }}
                  style={{
                    transform: `translate3d(${index *
                      (historyItemSize.current / 100) *
                      windowWidth}px, 0, 0)`,
                  }}
                />
              );
            })}
          </div>

          <div
            className={Styles.timelineCarousel}
            onMouseDown={onPressStart}
            onMouseMove={onPressMove}
            onMouseUp={onPressEnd}
            onTouchStart={onPressStart}
            onTouchMove={onPressMove}
            onTouchEnd={onPressEnd}
            ref={carouselRef}
            style={{
              width: `${historyItems.length * 100}%`,
            }}
          >
            {historyItems.map(
              (
                {
                  eyebrow: itemEyebrow,
                  title: itemTitle,
                  description: itemSubtitle,
                },
                index
              ) => {
                return (
                  <div
                    key={index}
                    className={Styles.historyItem}
                    ref={r => {
                      historyItemRefs.current[index] = r;
                    }}
                    style={{
                      width: `${historyItemSize.current /
                        historyItems.length}%`,
                      transitionDelay: `${500 + index * 100}ms`,
                    }}
                  >
                    <div className={Styles.historyItemEyebrow}>
                      {itemEyebrow}
                    </div>
                    <div className={Styles.historyItemTitle}>{itemTitle}</div>
                    <div className={Styles.historyItemSubtitle}>
                      {itemSubtitle}
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div className={Styles.swipeDirections}>
            <div className={Styles.swipeText}>Swipe for more</div>
          </div>
        </div>
      )}
    </InView>
  );
};

const getItemSize = width => {
  if (width < 600) {
    return 80;
  }

  if (width < 1024) {
    return 60;
  }

  if (width < 1200) {
    return 40;
  }

  return 33;
};

export default OurHistory;
