import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import BoardMemberCarousel from './BoardMemberCarousel';

const BoardMemberCarouselQuery = props => {
  const heroEyebrow = idx(props, _ => _.heroEyebrow);
  const heroTitle = idx(props, _ => _.heroTitle);
  const heroSubtitle = idx(props, _ => _.heroSubtitle);
  const boardMembers = (idx(props, _ => _.boardMembers) || []).map(member => {
    return {
      title: idx(member, _ => _.title),
      name: idx(member, _ => _.name),
      bio: idx(member, _ => _.bio.childMarkdownRemark.html),
      image: idx(member, _ => _.image),
    };
  });

  return (
    <BoardMemberCarousel
      heroEyebrow={heroEyebrow}
      heroTitle={heroTitle}
      heroSubtitle={heroSubtitle}
      boardMembers={boardMembers}
    />
  );
};

export default BoardMemberCarouselQuery;

export const query = graphql`
  fragment BoardMemberCarousel on ContentfulBoardMembersModule {
    heroEyebrow
    heroTitle
    heroSubtitle
    boardMembers {
      name
      title
      bio {
        childMarkdownRemark {
          html
        }
      }
      image {
        description
        fluid(maxWidth: 1200, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;
