import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import OurHistory from './OurHistory';

const OurHistoryQuery = props => {
  const eyebrow = idx(props, _ => _.eyebrow);
  const title = idx(props, _ => _.title);
  const subtitle = idx(props, _ => _.subtitle);
  const timelineDetails = (idx(props, _ => _.timelineDetails) || []).map(
    detail => {
      const detailEyebrow = idx(detail, _ => _.eyebrow);
      const detailTitle = idx(detail, _ => _.title);
      const detailDescription = idx(detail, _ => _.description);

      return {
        eyebrow: detailEyebrow,
        title: detailTitle,
        description: detailDescription,
      };
    }
  );

  return (
    <OurHistory
      eyebrow={eyebrow}
      title={title}
      subtitle={subtitle}
      timelineDetails={timelineDetails}
    />
  );
};

export default OurHistoryQuery;

export const query = graphql`
  fragment OurHistoryModule on ContentfulOurHistoryModule {
    eyebrow
    title
    subtitle
    timelineDetails {
      eyebrow
      title
      description
    }
  }
`;
