import React, { useState } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { InView } from 'react-intersection-observer';
import { css } from '@utils';
import Styles from './QuoteModule.module.scss';

const QuoteModule = ({
  quoteCopy,
  authorTitle,
  authorName,
  backgroundImage,
  mobileBackgroundImage,
}) => {
  const [desktopImageLoaded, setDesktopImageLoaded] = useState(false);
  const [mobileImageLoaded, setMobileImageLoaded] = useState(false);

  return (
    <InView triggerOnce threshold={0.5}>
      {({ inView, ref }) => (
        <div
          ref={ref}
          className={css(
            Styles.quoteModule,
            mobileImageLoaded && Styles.mobileImageLoaded,
            desktopImageLoaded && Styles.desktopImageLoaded,
            inView && Styles.inView
          )}
        >
          <div className={Styles.backgroundImage}>
            {backgroundImage && backgroundImage.fluid && (
              <Img
                className={Styles.backgroundGatsbyImage}
                fluid={backgroundImage.fluid}
                onLoad={() => {
                  setDesktopImageLoaded(true);
                }}
              />
            )}
          </div>

          <div className={Styles.mobileBackgroundImage}>
            {mobileBackgroundImage && mobileBackgroundImage.fluid && (
              <Img
                className={Styles.backgroundGatsbyImage}
                fluid={mobileBackgroundImage.fluid}
                objectFit="contain"
                objectPosition="top center"
                onLoad={() => {
                  setMobileImageLoaded(true);
                }}
              />
            )}
          </div>

          <div className={Styles.copyContainer}>
            <div className={Styles.quote}>
              <span className={Styles.startQuote}>“</span>
              <span className={Styles.quoteCopy}>{quoteCopy}</span>
              <span className={Styles.endQuote}>”</span>
            </div>

            <div className={Styles.authorCopy}>
              <div className={Styles.authorName}>{authorName}</div>
              <div className={Styles.authorTitle}>{authorTitle}</div>
            </div>
          </div>
        </div>
      )}
    </InView>
  );
};

export default QuoteModule;
