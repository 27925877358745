import React, { useState } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { InView } from 'react-intersection-observer';
import { css } from '@utils';
import Styles from './LetterFromDenis.module.scss';

const LetterFromDenis = ({
  eyebrow,
  title,
  heroImage,
  bodyParagraph,
  signatureImage,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <InView triggerOnce threshold={0.2}>
      {({ inView, ref }) => (
        <div
          ref={ref}
          className={css(
            Styles.letter,
            imageLoaded && Styles.imageLoaded,
            inView && Styles.inView
          )}
        >
          <div className={Styles.copyContainer}>
            <div className={Styles.backgroundMask} />
            <div className={Styles.eyebrow}>{eyebrow}</div>
            <div className={Styles.title}>{title}</div>
            <div className={Styles.heroImageContainer}>
              {heroImage && heroImage.fluid && (
                <Img
                  className={css(Styles.heroImage)}
                  fluid={heroImage.fluid}
                  onLoad={() => {
                    setImageLoaded(true);
                  }}
                />
              )}
            </div>
            <div
              className={Styles.bodyParagraph}
              dangerouslySetInnerHTML={{ __html: bodyParagraph }}
            />

            <div className={Styles.letterFooter}>
              <div className={Styles.signatureImage}>
                {signatureImage && signatureImage.fluid && (
                  <Img
                    className={Styles.signatureGatsbyImage}
                    fluid={signatureImage.fluid}
                  />
                )}
              </div>
              <div className={Styles.letterAuthor}>Denis Leary</div>
            </div>
          </div>
        </div>
      )}
    </InView>
  );
};

export default LetterFromDenis;
