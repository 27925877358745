import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import ReactDOM from 'react-dom';
import { css, q } from '@utils';
import Styles from './YoutubeIframe.module.scss';

const YoutubeIframe = ({ youtubeVideoId, active, onClose }) => {
  const [playerReady, setPlayerReady] = useState(false);
  const [portalEl, setPortalEl] = useState(null);
  const [youtubePlayer, setYoutubePlayer] = useState(null);

  const onPlayerReady = e => {
    setYoutubePlayer(e.target);
    setPlayerReady(true);
  };

  const onCloseClick = () => {
    if (youtubePlayer) {
      youtubePlayer.pauseVideo();
    }

    onClose();
  };

  useEffect(() => {
    setPortalEl(q('body'));
  }, []);

  return (
    <div className={Styles.youtubeIframe}>
      {portalEl &&
        ReactDOM.createPortal(
          <div
            className={css(Styles.youtubeContainer, active && Styles.active)}
          >
            <button
              type="button"
              className={Styles.closeYoutubeButton}
              onClick={onCloseClick}
            >
              Close
            </button>
            {active && (
              <div
                className={css(
                  Styles.youtubeIframe,
                  playerReady && Styles.ready
                )}
              >
                <YouTube
                  videoId={youtubeVideoId}
                  onReady={onPlayerReady}
                  opts={{
                    width: '100%',
                    playerVars: {
                      // https://developers.google.com/youtube/player_parameters
                      autoplay: 1,
                    },
                  }}
                />
              </div>
            )}
          </div>,
          portalEl
        )}
    </div>
  );
};

export default YoutubeIframe;
