import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import QuoteModule from './QuoteModule';

const QuoteModuleQuery = props => {
  const quoteCopy = idx(props, _ => _.quoteCopy);
  const authorName = idx(props, _ => _.authorName);
  const authorTitle = idx(props, _ => _.authorTitle);
  const backgroundImage = idx(props, _ => _.backgroundImage);
  const mobileBackgroundImage = idx(props, _ => _.mobileBackgroundImage);

  return (
    <QuoteModule
      quoteCopy={quoteCopy}
      authorName={authorName}
      authorTitle={authorTitle}
      backgroundImage={backgroundImage}
      mobileBackgroundImage={mobileBackgroundImage}
    />
  );
};

export const query = graphql`
  fragment QuoteModule on ContentfulQuoteModule {
    quoteCopy
    authorName
    authorTitle
    backgroundImage {
      description
      fluid(maxWidth: 1920, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    mobileBackgroundImage {
      description
      fluid(maxWidth: 768, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;

export default QuoteModuleQuery;
