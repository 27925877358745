import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import LetterFromDenis from './LetterFromDenis';

const LetterFromDenisQuery = props => {
  const eyebrow = idx(props, _ => _.eyebrow);
  const title = idx(props, _ => _.title);
  const bodyParagraph = idx(
    props,
    _ => _.bodyParagraph.childMarkdownRemark.html
  );
  const heroImage = idx(props, _ => _.heroImage);
  const signatureImage = idx(props, _ => _.signatureImage);

  return (
    <LetterFromDenis
      eyebrow={eyebrow}
      title={title}
      bodyParagraph={bodyParagraph}
      heroImage={heroImage}
      signatureImage={signatureImage}
    />
  );
};

export default LetterFromDenisQuery;

export const query = graphql`
  fragment LetterFromDenis on ContentfulLetterFromDenis {
    eyebrow
    title
    heroImage {
      description
      fluid(maxWidth: 1200, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    bodyParagraph {
      childMarkdownRemark {
        html
      }
    }
    signatureImage {
      description
      fluid(maxWidth: 180, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
