import React, { useState, useRef, useEffect } from 'react';
import YoutubeIframe from '@shared/YoutubeIframe/YoutubeIframe';
import { css, isIE } from '@utils';
import Styles from './Video.module.scss';

const Video = ({
  inView,
  src,
  youtubeVideoId,
  metaInfo,
  active,
  playNextVideo,
  youtubeActive,
  onYoutubeClose,
}) => {
  const videoRef = React.createRef();
  const [canPlay, setCanPlay] = useState(false);
  let canPlayTimeoutId = null;

  const onEnded = () => {
    if (canPlay) {
      playNextVideo();
    }
  };

  const onCanPlay = () => {
    canPlayTimeoutId = setTimeout(() => {
      if (active && videoRef && videoRef.current) {
        videoRef.current.play();
        setCanPlay(true);
      }
    }, 1200);
  };

  useEffect(() => {
    if (active) {
      if (canPlay) {
        videoRef.current.play();
      }
      if (!isIE) {
        videoRef.current.currentTime = 0;
      }
    }

    return () => {
      clearTimeout(canPlayTimeoutId);
    };
  }, [active]);

  useEffect(() => {
    if (active) {
      if (canPlay) {
        videoRef.current.play();
      } else if (!isIE) {
        videoRef.current.currentTime = 0;
      }
    }
  }, [canPlay]);

  const onClose = () => {
    onYoutubeClose();
  };

  return (
    <div
      className={css(
        Styles.videoContainer,
        active && inView && Styles.active,
        canPlay && Styles.previouslyActive
      )}
    >
      <video
        ref={videoRef}
        className={css(Styles.videoPreview, active && Styles.active)}
        playsInline
        muted
        alt={metaInfo}
        onCanPlay={onCanPlay}
        onEnded={onEnded}
      >
        <source src={src} type="video/mp4" />
        Sorry, your browser doesn&apos;t support embedded videos.
      </video>

      <YoutubeIframe
        youtubeVideoId={youtubeVideoId}
        active={youtubeActive}
        onClose={onClose}
      />
    </div>
  );
};

export default Video;
